import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import Logo from '../Logo';

import * as styles from './MinimalNavbar.module.scss';

function MinimalNavbar() {
  return (
    <nav className={styles.navbar}>
      <Link to="/" aria-label="Gå till rocker.com">
        <Logo color="dark" height={24} width={null} />
      </Link>
    </nav>
  );
}

export default MinimalNavbar;
