import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import Logo from '../Logo';

import * as styles from './MinimalFooter.module.scss';

function MinimalFooter() {
  return (
    <footer className={styles.footer}>
      <Link to="/" aria-label="Gå till rocker.com">
        <Logo color="dark" height={24} width={null} />
      </Link>
    </footer>
  );
}

export default MinimalFooter;
