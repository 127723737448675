import React from 'react';
import { createPortal } from 'react-dom';
import PrimaryButton from './primaryButton';

import * as _styles from './invalidReferralCodeDialog.module.css';
const styles: any = _styles;

function InvalidReferralCodeDialog() {
  const doc = typeof document === 'undefined' ? undefined : document.body;
  return createPortal(
    <div className={styles.wrapper}>
      <div className={styles.backdrop} />
      <div className={styles.dialog}>
        <h1>Felaktig kod</h1>
        <p>
          Vi hittar inte koden du angivit. Vänligen dubbelkolla att den stämmer.
        </p>
        <PrimaryButton to="/">Tillbaka till startsidan</PrimaryButton>
      </div>
    </div>,
    doc,
  );
}

export default InvalidReferralCodeDialog;
