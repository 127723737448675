import { useEffect, useState } from 'react';
import useIsProduction from './useIsProduction.hook';

const useIsReferralCodeValid = (referralCode: string) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isProduction = useIsProduction();

  useEffect(() => {
    setIsLoading(true);
    const fetchResult = async () => {
      const apiUrl = isProduction
        ? `https://card.rockerapi.dev`
        : `https://card-test.rockerapi.dev`;

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append(
        'Authorization',
        'Basic YXBpX3VzZXJfdGVzdDpZV1U0TUdJMVptRXRPRGczTlMwME16TXlMV0U1TnpjdFlUUmtZMkptWWpreE1XRms=',
      );

      try {
        const res = await fetch(`${apiUrl}/card/referral/${referralCode}`, {
          headers,
        });

        setIsValid(res.ok);
        setIsLoading(false);
      } catch (e) {
        setIsValid(false);
        setIsLoading(false);
      }
    };

    fetchResult();
  }, [referralCode]);

  return {
    isValid,
    isLoading,
  };
};

export default useIsReferralCodeValid;
